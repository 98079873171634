<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-subtitle>
          Select fields for target file matching, select email personalizations and import file.
        </v-card-subtitle>
        <div
          class="mb-5 text-center"
        >
          Target File:
          <div>
            <v-chip color="success">
              <span class="font-weight-bold">{{ $route.query.sc }}</span>
            </v-chip>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <v-card class="ml-3 mr-3 mb-5">
              <v-card-title> Column Setup </v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <span>Target file Matching</span>
                </v-col>
                <v-switch
                  v-model="isColumnNames"
                  label="First Row Contains Column Names"
                  class="ml-9"
                />
              </v-row>
              <v-row
                class="ml-2 mr-2"
                style="justify-content: center"
              >
                <v-col cols="3">
                  <v-select
                    v-model="meNumber"
                    :disabled="isMeDisabled"
                    :items="computedHeaders"
                    return-object
                    dense
                    outlined
                    label="ME Number"
                    color="primary"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="mixNumber"
                    :disabled="isMeMixedDisabled"
                    :items="computedHeaders"
                    return-object
                    dense
                    outlined
                    label="Mixed ME Number"
                    color="primary"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="decile"
                    :items="computedHeaders"
                    return-object
                    dense
                    outlined
                    label="Decile"
                    color="primary"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="npiNumber"
                    :disabled="isNPIDisabled"
                    :items="computedHeaders"
                    return-object
                    dense
                    outlined
                    label="NPI Number"
                    color="primary"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="firstName"
                    :disabled="isFNDisabled"
                    :items="computedHeaders"
                    return-object
                    dense
                    outlined
                    label="First Name"
                    color="primary"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="lastName"
                    :disabled="isLNDisabled"
                    :items="computedHeaders"
                    return-object
                    dense
                    outlined
                    label="Last Name"
                    color="primary"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="zipCode"
                    :disabled="isZipDisabled"
                    :items="computedHeaders"
                    return-object
                    dense
                    outlined
                    label="Zip Code"
                    color="primary"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ml-3 mr-3 mb-5">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-center">Email Personalizations and Additional Custom Fields</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  style="justify-content: center"
                >
                  <v-col
                    v-for="(item, i) in customFields"
                    :key="i"
                    :value="item"
                    cols="2"
                    class="ml-1"
                  >
                    <v-select
                      v-model="item.name"
                      :items="computedHeaders"
                      return-object
                      dense
                      outlined
                      :disabled="validateDisabled(item.name, i)"
                      :label="item.text"
                      :rules="[rules.counter]"
                      class="input-max-width"
                      color="primary"
                      @change="getCustomFieldValue(item.name, i)"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <v-card class="ml-3 mr-3">
          <v-card-title>
            File Preview
          </v-card-title>
          <v-row class="ml-5">
            <v-col cols="auto">
              <v-data-table
                :headers="computedHeaders"
                :items="computedRows"
                disable-pagination
                hide-default-footer
                disable-filtering
              />
            </v-col>
          </v-row>
          <v-col>
            <small
              v-if="importError !== ''"
              class="ml-10 mb-5"
              style="color: red"
            >
              {{ importError }}
            </small>
          </v-col>
          <v-card-actions class="mb-5">
            <v-btn
              color="success"
              :loading="importLoading"
              @click="validateImport()"
            >
              Import file
            </v-btn>
            <v-btn
              color="tertiary"
              @click="cancel()"
            >
              cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { azBlobService, nowwService } from '@/shared/services'
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'ImportTargetFile',

  props: {
    stepVal: {
      type: Number,
      default: 2
    },
    row0: {
      type: String,
      default: ''
    },
    fileArray: {
      type: Array,
      default: () => ([])
    },
    fileData: {
      type: String,
      default: ''
    },
    selectedMatch: {
      type: Array,
      default: () => ([])
    },
    amaSelected: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      results: [],
      rules: {
        counter: value => value.text.length >= 3 || 'Min 3 characters'
      },
      valid: false,
      isColumnNames: false,
      selectedItem: '',
      selectedCustomField: '',
      importError: '',
      meNumber: '',
      mixNumber: '',
      decile: '',
      npiNumber: '',
      firstName: '',
      lastName: '',
      zipCode: '',
      targetDataFile: null,
      resultsLoading: false,
      importLoading: false,
      isMeDisabled: true,
      isMeMixedDisabled: true,
      isNPIDisabled: true,
      isFNDisabled: true,
      isLNDisabled: true,
      isZipDisabled: true,
      headerArray: [],
      row0Array: [],
      isCustomFieldSelected: false,
      customFields: [{ name: '', text: 'custom field 1', value: 'cusFld1' }, { name: '', text: 'custom field 2', value: 'cusFld2' }, { name: '', text: 'custom field 3', value: 'cusFld3' }, { name: '', text: 'custom field 4', value: 'cusFld4' }, { name: '', text: 'custom field 5', value: 'cusFld5' },
        { name: '', text: 'custom field 6', value: 'cusFld6' }, { name: '', text: 'custom field 7', value: 'cusFld7' }, { name: '', text: 'custom field 8', value: 'cusFld8' }, { name: '', text: 'custom field 9', value: 'cusFld9' }, { name: '', text: 'custom field 10', value: 'cusFld10' },
        { name: '', text: 'custom field 11', value: 'cusFld11' }, { name: '', text: 'custom field 12', value: 'cusFld12' }, { name: '', text: 'custom field 13', value: 'cusFld13' }, { name: '', text: 'custom field 14', value: 'cusFld14' }, { name: '', text: 'custom field 15', value: 'cusFld15' },
        { name: '', text: 'custom field 16', value: 'cusFld16' }, { name: '', text: 'custom field 17', value: 'cusFld17' }, { name: '', text: 'custom field 18', value: 'cusFld18' }, { name: '', text: 'custom field 19', value: 'cusFld19' }, { name: '', text: 'custom field 20', value: 'cusFld20' }],
      fieldNames: [],
      restrictedFiledNames: ['NAME', 'LNAME', 'FNAME', 'MDDO', 'ADDR1', 'ADDR2', 'CITY', 'STATE', 'ZIP', 'ME NUMBER', 'MIXED ME NUMBER', 'NPI', 'EMAIL', 'ECODE', 'HOUR'],
      previewHeaders: [],
      // amaSelected: 0,
      apnSelected: 0,
      aapnSelected: 0,
      meSelected: 0,
      mixSelected: 0,
      nameSelected: 0,
      npiSelected: 0,
      meColumnNumber: '',
      mixMEColumnNumber: '',
      npiColumnNumber: '',
      firstNameColumn: '',
      lastNameColumn: '',
      zipCodeColumn: '',
      meIndex: '',
      meMixIndex: '',
      npiIndex: '',
      decileIndex: '',
      fnIndex: '',
      lnIndex: '',
      zipIndex: '',
      headerValue: [],
      matchSelectedColumns: []
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isLoading () {
      return this.loadingCount > 0
    },
    computedHeaders () {
      var headerObject = []
      var headers = []
      if (this.isColumnNames) {
        for (var i = 0; i < this.row0Array.length; i++) {
          headerObject = {
            text: this.row0Array[i],
            align: 'left',
            sortable: false,
            value: 'col00' + i
          }
          headers.push(headerObject)
        }
      } else {
        for (var j = 0; j < this.row0Array.length; j++) {
          var index = j + 1
          headerObject = {
            text: 'Col 00' + index,
            align: 'left',
            sortable: false,
            value: 'col00' + j
          }
          headers.push(headerObject)
        }
      }
      return headers
    },
    computedRows () {
      var i = !this.isColumnNames ? 0 : 1
      var headerValue = []
      var splitArray = []
      var stringSplit = []

      // Get amount of headers and assign a dynamic value
      for (var j = 0; j < this.row0Array.length; j++) {
        var columnValue = 'col00' + j
        headerValue.push(columnValue)
      }
      // split file values into strings array - change to 9 to display only 9 entries
      for (i; i < this.fileArray.length; i++) {
        if (i === 10) {
          break // Exit the loop after 9 iterations
        }
        splitArray = this.fileArray[i].split(',')
        stringSplit.push(splitArray)
      }

      // Assign new object from string arrays
      var rowsResult = stringSplit.map(value => Object.assign({}, ...headerValue.map((key, l) =>
        ({ [key]: value[l] }))))

      return rowsResult
    }
  },

  watch: {
    computedHeaders: {
      handler: function (newVal) {
        this.headerArray = newVal
      }
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.loadTargetData()
      }
    })
  },

  created () {
    this.getRow0Array(this.row0)
    this.validateCSV()
    this.scrollTop()
    this.getTargetDataHeader()
    this.getHeaders()
  },

  methods: {
    scrollTop () {
      setTimeout(() => {
        window.scrollTo({
          top: 0
        })
      }, 0)
    },
    clearErrors () {
      this.importError = ''
    },
    getMatchLogic () {
      if (this.results !== null) {
        for (var i = 0; i < this.results.length; i++) {
          if (this.results[i].match10 || this.results[i].match11) {
            this.isMeDisabled = false
          }
          if (this.results[i].matchMixed) {
            this.isMeMixedDisabled = false
          }
          if (this.results[i].matchName) {
            this.isFNDisabled = false
            this.isLNDisabled = false
            this.isZipDisabled = false
          }
          if (this.results[i].matchNPI) {
            this.isNPIDisabled = false
          }
        }
      }
    },
    getRow0Array (value) {
      this.row0Array = value.split(',')
    },
    cancel () {
      this.$emit('cancelStep', this.stepVal)
    },
    validateCSV () {
      var reValid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/
      var reValue = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g
      // Return NULL if input string is not well formed CSV string.
      if (!reValid.test(this.fileData)) return null
      var a = [] // Initialize array to receive values.
      this.fileData.replace(reValue, // "Walk" the string using replace with callback.
        function (m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"))
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'))
          else if (m3 !== undefined) a.push(m3)
          return '' // Return empty string.
        })
      // Handle special case of empty last value.
      if (/,\s*$/.test(this.fileData)) a.push('')
      return a
    },
    getHeaders () {
      for (var i = 0; i < this.row0Array.length; i++) {
        var columnValue = 'col00' + i
        this.headerValue.push(columnValue)
      }
      return this.headerValue
    },
    getCustomFieldValue (data, index) {
      for (var i = 0; i < this.customFields.length; i++) {
        if (data.text === this.customFields[i].text) {
          this.customFields[i].name = data[value]
        }
      }
    },
    validateDisabled (value, index) {
      var isDisabled = true
      if (index === 0) {
        isDisabled = false
        return
      }
      for (var i = 1; i < this.customFields.length; i++) {
        if (this.customFields[index - 1].name.text !== '' && this.customFields[index - 1].name !== '' && this.customFields[index - 1].name.text.length >= 3) {
          isDisabled = false
          return
        }
      }
      return isDisabled
    },
    async getTargetDataHeader () {
      var _this = this
      _this.resultsLoading = true
      await nowwService.getTargetDataHeader(parseInt(_this.$route.query.sid))
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            _this.getMatchLogic()
          }
        })
    },
    async validateImport () {
      var _this = this
      var mapArray = []
      var cusFieldArray = []
      _this.clearErrors()
      if (!_this.isMeDisabled && _this.meNumber === '') {
        _this.importError = 'Please select a column for the ME Number.'
        return
      } else if (!_this.isMeDisabled) {
        _this.meColumnNumber = _this.meNumber.text
        var meObj = Object.assign({}, {
          'fileColumnHeader': _this.meNumber.text,
          'nowwDataPropName': 'meField'
        })
        mapArray.push(meObj)
      }
      if (!_this.isMeMixedDisabled && _this.mixNumber === '') {
        _this.importError = 'Please select a column for the Mixed ME Number.'
        return
      } else if (!_this.isMeMixedDisabled) {
        _this.mixMEColumnNumber = _this.mixNumber.text
        var meMixObj = Object.assign({}, {
          'fileColumnHeader': _this.mixNumber.text,
          'nowwDataPropName': 'meMixField'
        })
        mapArray.push(meMixObj)
      }
      if (_this.decile !== '') {
        var decileObj = Object.assign({}, {
          'fileColumnHeader': _this.decile.text,
          'nowwDataPropName': 'decileField'
        })
        mapArray.push(decileObj)
      }
      if (!_this.isNPIDisabled && _this.npiNumber === '') {
        _this.importError = 'Please select a column for the NPI Number.'
        return
      } else if (!_this.isNPIDisabled) {
        _this.npiColumnNumber = _this.npiNumber.text
        var npiObj = Object.assign({}, {
          'fileColumnHeader': this.npiNumber.text,
          'nowwDataPropName': 'npiField'
        })
        mapArray.push(npiObj)
      }
      if (!_this.isFNDisabled) {
        if (_this.firstName === '') {
          _this.importError = 'Please select a column for the First Name.'
        } else if (_this.lastName === '') {
          _this.importError = 'Please select a column for the Last Name.'
        } else if (_this.zipCode === '') {
          _this.importError = 'Please select a column for the Zip Code.'
        } else {
          _this.firstNameColumn = _this.firstName.text
          var fnObj = Object.assign({}, {
            'fileColumnHeader': _this.firstName.text,
            'nowwDataPropName': 'fnField'
          })
          mapArray.push(fnObj)
          _this.lastNameColumn = _this.lastName.text
          var lnObj = Object.assign({}, {
            'fileColumnHeader': _this.lastName.text,
            'nowwDataPropName': 'lnField'
          })
          mapArray.push(lnObj)
          _this.zipCodeColumn = _this.zipCode.text
          var zipObj = Object.assign({}, {
            'fileColumnHeader': _this.zipCode.text,
            'nowwDataPropName': 'zipField'
          })
          mapArray.push(zipObj)
        }
      }

      // Map fields to proper value from uploaded file
      for (var m = 0; m < _this.computedHeaders.length; m++) {
        if (_this.computedHeaders[m].text === _this.meNumber.text) {
          _this.meIndex = m.toString()
        }
        if (_this.computedHeaders[m].text === _this.npiNumber.text) {
          _this.npiIndex = m.toString()
        }
        if (_this.computedHeaders[m].text === _this.mixNumber.text) {
          _this.meMixIndex = m.toString()
        }
        if (_this.computedHeaders[m].text === _this.decile.text) {
          _this.decileIndex = m.toString()
        }
        if (_this.computedHeaders[m].text === _this.firstName.text) {
          _this.fnIndex = m.toString()
        }
        if (_this.computedHeaders[m].text === _this.lastName.text) {
          _this.lnIndex = m.toString()
        }
        if (_this.computedHeaders[m].text === _this.zipCode.text) {
          _this.zipIndex = m.toString()
        }
      }

      // Logic for custom fields - look for restricted field names - map custom field to file column
      for (var i = 0; i < _this.customFields.length; i++) {
        var fieldData = ''
        if (_this.customFields[i].name.text !== '' && _this.customFields[i].name.text !== undefined && _this.customFields[i].name !== '') {
          for (var k = 0; k < _this.restrictedFiledNames.length; k++) {
            if (_this.customFields[i].name.text.toUpperCase().includes(_this.restrictedFiledNames[k])) {
              _this.importError = `The field name "${_this.restrictedFiledNames[k]}" cannot be used in Personalization Fields.`
              return
            }
            if (_this.customFields[i].name.text.length < 3) {
              _this.importError = `Custom field name requires at least 3 characters`
              return
            }
          }
        }
        if (_this.importError === '' && _this.customFields[i].name.text !== '') {
          fieldData = _this.customFields[i].name.text
          if (_this.customFields[i].name !== '') {
            var customObj = Object.assign({}, {
              'fileColumnHeader': _this.customFields[i].name.text,
              'nowwDataPropName': _this.customFields[i].value
            })
            mapArray.push(customObj)
            cusFieldArray.push(customObj)
          }
          _this.fieldNames.push(fieldData)
        }
      }
      _this.getMatchLogic()
      if (_this.importError === '') {
        _this.importLoading = true
      }

      var customerNowwId = this.$store.getters['simulatedCustomerNowwId']().toString()
      await azBlobService.uploadtdmap({
        nowwId: customerNowwId,
        targetDataId: _this.$route.query.sid,
        tdMapping: mapArray
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.uploadResults = resp
          }
        })
      for (var l = 0; l < _this.selectedMatch.length; l++) {
        var matchObj = Object.assign({}, {
          'SelectedMatchColumn': _this.selectedMatch[l].value
        })
        _this.matchSelectedColumns.push(matchObj)
      }
      await nowwService.targetdDataUploadMatch({
        supressionId: _this.$route.query.sid,
        targetDataName: _this.$route.query.sc,
        dtFinal: _this.fileArray,
        cusFieldMapping: cusFieldArray,
        meIndex: _this.meIndex,
        meMixIndex: _this.meMixIndex,
        npiIndex: _this.npiIndex,
        decileIndex: _this.decileIndex,
        firstNameIndex: _this.fnIndex,
        lastNameIndex: _this.lnIndex,
        zipIndex: _this.zipIndex
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            _this.$emit('advanceFromStep', _this.stepVal)
          }
        })
        .finally(() => {
          _this.importLoading = false
        })
    }
  }

}
</script>
