var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-card-subtitle", [
                _vm._v(
                  "\n        Select fields for target file matching, select email personalizations and import file.\n      "
                )
              ]),
              _c("div", { staticClass: "mb-5 text-center" }, [
                _vm._v("\n        Target File:\n        "),
                _c(
                  "div",
                  [
                    _c("v-chip", { attrs: { color: "success" } }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$route.query.sc))
                      ])
                    ])
                  ],
                  1
                )
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ml-3 mr-3 mb-5" },
                        [
                          _c("v-card-title", [_vm._v(" Column Setup ")]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" }
                                },
                                [_c("span", [_vm._v("Target file Matching")])]
                              ),
                              _c("v-switch", {
                                staticClass: "ml-9",
                                attrs: {
                                  label: "First Row Contains Column Names"
                                },
                                model: {
                                  value: _vm.isColumnNames,
                                  callback: function($$v) {
                                    _vm.isColumnNames = $$v
                                  },
                                  expression: "isColumnNames"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "ml-2 mr-2",
                              staticStyle: { "justify-content": "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: _vm.isMeDisabled,
                                      items: _vm.computedHeaders,
                                      "return-object": "",
                                      dense: "",
                                      outlined: "",
                                      label: "ME Number",
                                      color: "primary"
                                    },
                                    model: {
                                      value: _vm.meNumber,
                                      callback: function($$v) {
                                        _vm.meNumber = $$v
                                      },
                                      expression: "meNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: _vm.isMeMixedDisabled,
                                      items: _vm.computedHeaders,
                                      "return-object": "",
                                      dense: "",
                                      outlined: "",
                                      label: "Mixed ME Number",
                                      color: "primary"
                                    },
                                    model: {
                                      value: _vm.mixNumber,
                                      callback: function($$v) {
                                        _vm.mixNumber = $$v
                                      },
                                      expression: "mixNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.computedHeaders,
                                      "return-object": "",
                                      dense: "",
                                      outlined: "",
                                      label: "Decile",
                                      color: "primary"
                                    },
                                    model: {
                                      value: _vm.decile,
                                      callback: function($$v) {
                                        _vm.decile = $$v
                                      },
                                      expression: "decile"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: _vm.isNPIDisabled,
                                      items: _vm.computedHeaders,
                                      "return-object": "",
                                      dense: "",
                                      outlined: "",
                                      label: "NPI Number",
                                      color: "primary"
                                    },
                                    model: {
                                      value: _vm.npiNumber,
                                      callback: function($$v) {
                                        _vm.npiNumber = $$v
                                      },
                                      expression: "npiNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: _vm.isFNDisabled,
                                      items: _vm.computedHeaders,
                                      "return-object": "",
                                      dense: "",
                                      outlined: "",
                                      label: "First Name",
                                      color: "primary"
                                    },
                                    model: {
                                      value: _vm.firstName,
                                      callback: function($$v) {
                                        _vm.firstName = $$v
                                      },
                                      expression: "firstName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: _vm.isLNDisabled,
                                      items: _vm.computedHeaders,
                                      "return-object": "",
                                      dense: "",
                                      outlined: "",
                                      label: "Last Name",
                                      color: "primary"
                                    },
                                    model: {
                                      value: _vm.lastName,
                                      callback: function($$v) {
                                        _vm.lastName = $$v
                                      },
                                      expression: "lastName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: _vm.isZipDisabled,
                                      items: _vm.computedHeaders,
                                      "return-object": "",
                                      dense: "",
                                      outlined: "",
                                      label: "Zip Code",
                                      color: "primary"
                                    },
                                    model: {
                                      value: _vm.zipCode,
                                      callback: function($$v) {
                                        _vm.zipCode = $$v
                                      },
                                      expression: "zipCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-3 mr-3 mb-5" },
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("span", { staticClass: "text-center" }, [
                              _vm._v(
                                "Email Personalizations and Additional Custom Fields"
                              )
                            ])
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticStyle: { "justify-content": "center" }
                                },
                                _vm._l(_vm.customFields, function(item, i) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: i,
                                      staticClass: "ml-1",
                                      attrs: { value: item, cols: "2" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "input-max-width",
                                        attrs: {
                                          items: _vm.computedHeaders,
                                          "return-object": "",
                                          dense: "",
                                          outlined: "",
                                          disabled: _vm.validateDisabled(
                                            item.name,
                                            i
                                          ),
                                          label: item.text,
                                          rules: [_vm.rules.counter],
                                          color: "primary"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.getCustomFieldValue(
                                              item.name,
                                              i
                                            )
                                          }
                                        },
                                        model: {
                                          value: item.name,
                                          callback: function($$v) {
                                            _vm.$set(item, "name", $$v)
                                          },
                                          expression: "item.name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ml-3 mr-3" },
                [
                  _c("v-card-title", [
                    _vm._v("\n          File Preview\n        ")
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "ml-5" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.computedHeaders,
                              items: _vm.computedRows,
                              "disable-pagination": "",
                              "hide-default-footer": "",
                              "disable-filtering": ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", [
                    _vm.importError !== ""
                      ? _c(
                          "small",
                          {
                            staticClass: "ml-10 mb-5",
                            staticStyle: { color: "red" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.importError) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "mb-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "success",
                            loading: _vm.importLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateImport()
                            }
                          }
                        },
                        [_vm._v("\n            Import file\n          ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "tertiary" },
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v("\n            cancel\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }